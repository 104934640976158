import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Oscar Daniel Otero</h1><h3>.dev</h3>
    </div>
  );
}

export default App;
